const React = require("react")
const { Provider } = require("react-redux")
const store = require("./src/state/store").default
const Wallet = require('./src/contexts/wallet').default

exports.wrapRootElement = ({ element }) => {
  return (
    <Provider store={store}>
      <Wallet>
        {element}
      </Wallet>
    </Provider>
  )
}