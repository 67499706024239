// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-comic-js": () => import("./../../../src/pages/comic.js" /* webpackChunkName: "component---src-pages-comic-js" */),
  "component---src-pages-explorer-js": () => import("./../../../src/pages/explorer.js" /* webpackChunkName: "component---src-pages-explorer-js" */),
  "component---src-pages-guide-js": () => import("./../../../src/pages/guide.js" /* webpackChunkName: "component---src-pages-guide-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mynfts-js": () => import("./../../../src/pages/mynfts.js" /* webpackChunkName: "component---src-pages-mynfts-js" */),
  "component---src-pages-pass-js": () => import("./../../../src/pages/pass.js" /* webpackChunkName: "component---src-pages-pass-js" */),
  "component---src-pages-stake-js": () => import("./../../../src/pages/stake.js" /* webpackChunkName: "component---src-pages-stake-js" */)
}

