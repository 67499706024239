import { initialState } from './state'

const Types = {
    SET_WALLET: 'SET_WALLET',
    SET_WALLET_PROVIDER: 'SET_WALLET_PROVIDER',
    SET_WALLET_ADDRESS: 'SET_WALLET_ADDRESS',
    SET_CONNECTED: 'SET_CONNECTED'
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case Types.SET_WALLET:
            return {
                ...state,
                wallet: action.payload
            };
        case Types.SET_WALLET_PROVIDER:
            return {
                ...state,
                walletProvider: action.payload
            };
        case Types.SET_WALLET_ADDRESS:
            return {
                ...state,
                walletAddress: action.payload
            };
        case Types.SET_CONNECTED:
            return {
                ...state,
                connected: action.payload
            };
        default: return state
    }
}
export default reducer;